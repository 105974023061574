import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from './routes/Router';
import { AuthProvider } from './contexts/AuthContext';



const root = ReactDOM.createRoot(document.getElementById("root"));





root.render(
 // <React.StrictMode>
 <AuthProvider>
    <Router/>
    </AuthProvider>
 // </React.StrictMode>
 
);
