import React, { useState, useEffect, useCallback } from 'react';
//import { useStyles } from './Styles';
import httpClient from '../../HttpClient';
import { Typography, Grid, Container, Button } from '@mui/material';
import Loading from '../../IngelalPage/Igelal';
import KonalRamtukan from './components/Landing/KonalRamtukan';
import Ramtun from './components/Ramtun/Ramtun';
import Landing from './components/Landing/Landing';
import ChateamRuka from './components/Chateam/ChateamRuka';
import TxokinGvtxamRuka from './components/TxokinGvtxam/TxokinGvtxamRuka';
import { useNavigate } from 'react-router-dom';
import Igelal from '../../IngelalPage/Igelal';

const Ruka = ({ mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun, page, setPage, setZugun }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Manejo de estilos responsivos
    const [catchSentenceStyle, setCatchSentenceStyle] = useState({
        fontSize: "7vw",
        lineHeight: "9vw",
        fontWeight: "600",
    });

    const updateStyle = () => {
        const vw = window.innerWidth * 0.0065;
        const vh = window.innerHeight * 0.0065;
        const cresponsiveSize = (vh + vw) * 4;

        setCatchSentenceStyle({
            fontSize: cresponsiveSize + "px",
            lineHeight: cresponsiveSize * 1.1 + "px",
            fontWeight: "100",
        });
    };

    useEffect(() => {
        updateStyle();
        window.addEventListener("resize", updateStyle);
        return () => window.removeEventListener("resize", updateStyle);
    }, []);

    // Cargar datos iniciales y usuario autenticado
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await httpClient.get(`${process.env.REACT_APP_API_URL}/@me`, {
                    headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
                });
                setUser(res.data);
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener el usuario:", error);
                navigate('/konal', { state: { redir: true } });
            }
        };

        fetchUser();
    }, [navigate]);

    // Manejo de logout
      
    const txipayal = async () => {
      try {
          await httpClient.get(`${process.env.REACT_APP_API_URL}/logout`);
          setUser(null); // Limpiamos el estado del usuario
          navigate('/konal', { state: { redir: true } }); // Redirigir a la página de login
      } catch (error) {
          console.error("Error en logout:", error);
          navigate('/konal', { state: { redir: true } });
      }
  };

    const clickHandle = useCallback((n) => {
        setPage(n);
    }, [setPage]);

    return (
        <>
            {loading ? (
                <Igelal />
            ) : (
                <div style={{ width: "100%" }}>
                    <Container maxWidth="lg" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {loading | loadingZugun | !mapuzugun ? (
                            <Loading />
                        ) : (
                            <>
                                <Typography variant='body1' style={catchSentenceStyle} sx={{ padding: 3 }}>
                                    {mapuzugunPvneal ? mapuzugun.ruka.titulo : wigkazugun.ruka.titulo}
                                </Typography>
                            </>
                        )}
                        {user ? (
                            <Button variant='outlined' onClick={txipayal}>
                                {mapuzugunPvneal ? mapuzugun.txipayal : wigkazugun.txipayal}
                            </Button>
                        ) : (
                            <Loading />
                        )}
                    </Container>
                    {loading | loadingZugun | !mapuzugun ? (
                        <Loading />
                    ) : (
                        <>
                            {user && (
                                <Grid container columns={8} sx={{ justifyContent: "center" }}>
                                    <Grid item xs={8}>
                                        {page === 0 ? (
                                            <Landing
                                                user={user}
                                                chuchiPage={clickHandle}
                                                mapuzugun={mapuzugun}
                                                wigkazugun={wigkazugun}
                                                mapuzugunPvneal={mapuzugunPvneal}
                                            />
                                        ) : page === 1 ? (
                                            <KonalRamtukan
                                                user={user}
                                                chuchiPage={clickHandle}
                                                mapuzugun={mapuzugun}
                                                wigkazugun={wigkazugun}
                                                mapuzugunPvneal={mapuzugunPvneal}
                                            />
                                        ) : page === 2 ? (
                                            <ChateamRuka
                                                user={user}
                                                chuchiPage={clickHandle}
                                                mapuzugun={mapuzugun}
                                                wigkazugun={wigkazugun}
                                                mapuzugunPvneal={mapuzugunPvneal}
                                            />
                                        ) : page === 3 ? (
                                            <TxokinGvtxamRuka
                                                user={user}
                                                chuchiPage={clickHandle}
                                                mapuzugun={mapuzugun}
                                                wigkazugun={wigkazugun}
                                                mapuzugunPvneal={mapuzugunPvneal}
                                            />
                                        ) : page === 4 ? (
                                            <Ramtun
                                                user={user}
                                                chuchiPage={clickHandle}
                                                mapuzugun={mapuzugun}
                                                wigkazugun={wigkazugun}
                                                mapuzugunPvneal={mapuzugunPvneal}
                                            />
                                        ) : null}
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default Ruka;
