import { Card, Typography, Button, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"


const KonalRamtukan = ({amualRamtun,mapuzugun, wigkazugun, mapuzugunPvneal, paragraphStyle, catchSentenceStyle})=>{
    console.log('Props en KonalRamtukan:', { amualRamtun, mapuzugun, wigkazugun, mapuzugunPvneal });

    
    const clickHandle = ()=>{
        amualRamtun(4)
    }
    return(
        <Card sx={{margin:2,padding:4}}>
            <Stack spacing={4} >
            <Typography variant="body1" style={catchSentenceStyle}>
                {mapuzugunPvneal?mapuzugun.konalRamtun.title:wigkazugun.konalRamtun.title}
                
            </Typography>
            <Typography variant="body1" style={paragraphStyle}>
            {mapuzugunPvneal?mapuzugun.konalRamtun.des:wigkazugun.konalRamtun.des}
                
            </Typography>
            <Button variant="contained" onClick={clickHandle}>
            {mapuzugunPvneal?mapuzugun.konalRamtun.amualbut:wigkazugun.konalRamtun.amualbut}
                
            </Button>
            </Stack>
        </Card>
        
    )

}




export  default KonalRamtukan