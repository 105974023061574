import React, {useState,useEffect} from 'react';
import httpClient from '../../HttpClient';
import { Typography,TextField, Stack, Button ,Grid, Snackbar, Alert} from '@mui/material';
import { ChumteNewenGeiPass } from '../ChumteNewenGeiPass/ChumteNewenGeiPass';
import {useParams } from 'react-router-dom';
import Igelal from '../../IngelalPage/Igelal';

const ZewmatuafielKontrasena = ({mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun,setZugun, setLoadingZugun,user}) => {
    const [password, setPassword] = useState('')
    const [passwordkk, setPasswordKK] = useState('')
    const [kaletui, setKaletui] = useState(false)
    const [validated, setValidated] = useState(true)
    const [kvmeTxipalay, setKvmeTxipalay] = useState(true)
    const [validatedMessage, setValidatedMessage] = useState(false)
    const [passNotEqual, setPassNotEqual] = useState(false)
    const [passMessage, setPassMessage] = useState(false)
    const [passStatus, setPassStatus] = useState(-1)
    const [loading, setLoading] = useState(true)
    const vertical = 'bottom';
    const horizontal = 'right'; 
    let {token} = useParams()

    
    useEffect(()=>{
      //console.log(user)
      console.log('PASO USEFFECT',user)      
      console.log(location)
      if (location.state &&  location.state.redir ){
        console.log('REDIR ?SI ')
        mapuzugun = location.state.mapuzugun
        wigkazugun = location.state.wigkazugun
        mapuzugunPvneal = location.state.mapuzugunPvneal
        loadingZugun = location.loadingZugun
        setLoading(loadingZugun)
      }else {
          console.log('REDIR ?NO ')
          const respz =  httpClient.get(process.env.REACT_APP_API_URL+'/epuzugun').then((res) => {//console.log(res, 'success')
          //console.log(res)
          setZugun(res.data.mapuzugun,res.data.wigkazugun)
          setLoading(false)
          setLoadingZugun(false)
          
          })
            .catch(error => console.log(error, 'error'))

        const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/@me',
                            {headers:{"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}})
                            .then((res) => {console.log( res,'success');}).catch(error => console.log(error, 'error') )
        
        //console.log(resp)
        
       
        
      }


      ;
  },[])
  

const validatePassword = (password) => {
    const min_req = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    const med_req = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    const med_p_req = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    const max_req = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    console.log("pre_length_val")
    if (password.length <8) {
      setPassStatus(-1)
      return setValidated(false)}
    if (max_req.test(password)){
      setPassStatus(4)
      console.log("MAXLEVEL")
    }
    else if (med_p_req.test(password)){
      setPassStatus(3)
      console.log("MIDMAXLEVEL")
    }
    else if (med_req.test(password)){
        setPassStatus(2)
        console.log("MIDMINLEVEL")
    }
    else if (min_req.test(password)){
        setPassStatus(1)
        console.log("MINLEVEL")
    }
    else{
        setPassStatus(0)
        console.log("ERROR")
    }

    return setValidated(true)
  }


    const setNewPass= async ()=>{
      if (!validatePassword(password)){setValidated(false);setValidatedMessage(true)}
      if (password!==passwordkk){
        setPassNotEqual(true);
        setPassMessage(true);
        console.log('notequal');
        return
      }else{
        setPassNotEqual(false);
        setPassMessage(false);
        console.log('equal');
      }
      try{
        const resp = await httpClient.post(process.env.REACT_APP_API_URL+"/set_new_password",
        {
          new_password:password,
          token:token
        });
        console.log(1)
        console.log(resp.status, resp.statusText)
        setKaletui(true)
      }
      catch(error){
        if (error.response.status === 401){
            setKvmeTxipalay(true)

        }
      }
    };

  return (
    
    <Grid container spacing={2}
    sx={{
      display:'flex',
      width: '100%',
      height:'100%',
      alignItems: 'center',
      flexDirection:'row',
    }}
    
   
  >
    <Grid item xs={6} md={7}>
      <Grid container>
        <Grid  item   sx={{
          padding:5,
        justifyItems:'start',
          alignItems:'center',
          height:'100%',
          maxHeight: '80%',
          width:'auto'
          }}
          
      >
          <img src={`${process.env.PUBLIC_URL}/imgs/back.png`} alt='background' style={{width:"auto", maxHeight:"70vh",alignSelf:'center'}}/>
      
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6} md={5}>

      <Stack component="form"
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{marginRight:15,
        marginBottom:15,
         justifyItems:'center',
          alignItems:'center',
          width: 'auto',
          minWidth: '280px',
          maxWidth: '330px'}}
      
    >
{!loading & !loadingZugun?<> 
    
        {!kaletui?
        <>
            <img src={`${process.env.PUBLIC_URL}/imgs/logo.svg`} alt='background'  style={{width:'30%',alignSelf:'center'}}/>
            <Typography variant='h4'>
                {mapuzugunPvneal ? mapuzugun.zewmatuafiel.titulo: wigkazugun.zewmatuafiel.titulo}
                
            </Typography>
            
            <Grid container sx={{justifyContent:"space-between"}} spacing={1}>
          <Grid item xs={12}>
            <TextField
            fullWidth
              required
              error = {(!validated | passNotEqual)}
              id="passwordll"
              label="Password"
              type={"password"} 
              value={password}
              onChange={(e)=>{setPassword(e.target.value);validatePassword(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            fullWidth
              required
              error = {(!validated | passNotEqual) }
              id="passwordll"
              label="Password(Ka kiñe rupa)"
              type={"password"} 
              value={passwordkk}
              onChange={(e)=>setPasswordKK(e.target.value)}
            />
          </Grid>
        <Grid item xs={12}>
        <Button variant="contained" onClick={setNewPass} fullWidth >{mapuzugunPvneal ? mapuzugun.zewmatuafiel.zewmatuafielbut: wigkazugun.zewmatuafiel.zewmatuafielbut}</Button>
        
        </Grid>
        </Grid>
        {passStatus>-1?<ChumteNewenGeiPass level={passStatus} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}  />:null}
        
        {!validated?  <Snackbar anchorOrigin={{ vertical, horizontal }} open={validatedMessage} autoHideDuration={6000} onClose={()=>{setValidatedMessage(false)}}><Alert severity="warning">kümelai tami kontraseña </Alert></Snackbar>:null}
        {passNotEqual?  <Snackbar anchorOrigin={{ vertical, horizontal }} open={passMessage} autoHideDuration={6000} onClose={()=>{setPassMessage(false)}}><Alert severity="warning">kalei tami epu kontraseña </Alert></Snackbar>:null}
        {kvmeTxipalay?  <Snackbar anchorOrigin={{ vertical, horizontal }} open={kvmeTxipalay} autoHideDuration={6000} onClose={()=>{setKvmeTxipalay(false)}}><Alert severity="warning"> kaletulai tami kontraseña, ka kiñe rupa femaimi </Alert></Snackbar>:null}

        </>
        :
        <>
            <Typography variant='h3'>  {mapuzugunPvneal ? mapuzugun.zewmatuafiel.wekontrasena: wigkazugun.zewmatuafiel.wekontrasena}</Typography>
            
            <Button variant="contained" onClick={()=>{window.location.href='/konal'}} fullWidth > {mapuzugunPvneal ? mapuzugun.zewmatuafiel.konmealbut: wigkazugun.zewmatuafiel.konmealbut}</Button>
        </>
            }</>


            :<><Igelal/></>}
        </Stack>
      </Grid>
    </Grid>

  )
}

export default ZewmatuafielKontrasena