import React, { useState, useEffect } from 'react';
import httpClient from '../../HttpClient';
import { Typography, TextField, Stack, Button, Grid } from '@mui/material';
import Igelal from '../../IngelalPage/Igelal';
import { useNavigate, useLocation } from 'react-router-dom';

const TukupatulanKontrasena = ({ mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun, setZugun, setLoadingZugun }) => {
    const [email, setEmail] = useState('');
    const [sendedIfExists, setSendedIfExists] = useState(false);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log('Componente montado. Verificando datos iniciales...', location.state);

        if (location.state?.redir) {
            console.log('Cargando datos desde location.state...');
            setLoading(false);
            setLoadingZugun(false);
        } else {
            console.log('Cargando datos iniciales desde API...');
            const fetchData = async () => {
                try {
                    const respz = await httpClient.get(`${process.env.REACT_APP_API_URL}/epuzugun`);
                    setZugun(respz.data.mapuzugun, respz.data.wigkazugun);
                    setLoading(false);
                    setLoadingZugun(false);
                } catch (error) {
                    console.error('Error al cargar datos iniciales:', error);
                }
            };
            fetchData();
        }
    }, [location.state, setZugun, setLoadingZugun]);

    const passRequest = async () => {
        try {
            const resp = await httpClient.post(`${process.env.REACT_APP_API_URL}/send_reset_mail`, { email });
            console.log('Solicitud enviada:', resp.status, resp.statusText);
            setSendedIfExists(true);
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            setSendedIfExists(false);
        }
    };

    if (loading || loadingZugun) {
        return <Igelal />;
    }

    return (
        <Grid container spacing={2} sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', flexDirection: 'row' }}>
            <Grid item xs={6} md={7}>
                <Grid container>
                    <Grid
                        item
                        sx={{
                            padding: 5,
                            justifyItems: 'start',
                            alignItems: 'center',
                            height: '100%',
                            maxHeight: '80%',
                            width: 'auto',
                        }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/imgs/back.png`}
                            alt="background"
                            style={{ width: '70%', maxHeight: '70vh', alignSelf: 'center' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} md={5}>
                <Stack
                    component="form"
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    sx={{
                        marginRight: 15,
                        marginBottom: 15,
                        justifyItems: 'center',
                        alignItems: 'center',
                        width: 'auto',
                        minWidth: '280px',
                        maxWidth: '330px',
                    }}
                >
                    {!sendedIfExists ? (
                        <>
                            <img
                                src={`${process.env.PUBLIC_URL}/imgs/logo.svg`}
                                alt="background"
                                style={{ width: '30%', alignSelf: 'center' }}
                            />
                            <Typography variant="h4">
                                {mapuzugunPvneal ? mapuzugun.tukulpatulan.title : wigkazugun.tukulpatulan.title}
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button variant="contained" onClick={passRequest} fullWidth>
                                {mapuzugunPvneal ? mapuzugun.tukulpatulan.amulalbut : wigkazugun.tukulpatulan.amulalbut}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography>
                                {mapuzugunPvneal ? mapuzugun.tukulpatulan.nielmi : wigkazugun.tukulpatulan.nielmi}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/konal')}
                                fullWidth
                            >
                                {mapuzugunPvneal ? mapuzugun.tukulpatulan.konmealbut : wigkazugun.tukulpatulan.konmealbut}
                            </Button>
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default TukupatulanKontrasena;
