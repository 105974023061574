import { AppBar, Typography, Box, Toolbar, Switch, Stack } from '@mui/material';
import TypedText from './Components/TypedText';
import React, { useState, useEffect } from 'react';
import Notifications from './Notifications/Notifications';
import httpClient from '../HttpClient';

const LogkolBar = ({ changeZugun, mapuzugunPvneal, mapuzugun, wigkazugun, loadingZugun, setPage }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false); // Control para evitar reintentos

  useEffect(() => {
    if (authChecked) return; // Evitar llamadas repetidas

    const fetchUser = async () => {
      try {
        const res = await httpClient.get(process.env.REACT_APP_API_URL + '/@me', {
          headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
        });
        setUser(res.data);
        setLoggedIn(true);
        console.log('Usuario autenticado en LogkolBar');
      } catch (error) {
        console.warn('Usuario no autenticado en LogkolBar:', error.message);
      } finally {
        setAuthChecked(true); // Marcar como procesado
      }
    };

    fetchUser();
  }, [authChecked]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <TypedText />
          </Typography>
          {loggedIn ? (
            <Notifications
              mapuzugunPvneal={mapuzugunPvneal}
              user={user}
              loggedIn={loggedIn}
              setPage={setPage}
            />
          ) : null}

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Español</Typography>
            <Switch
              color="secondary"
              value={mapuzugunPvneal}
              defaultChecked
              onChange={changeZugun}
            ></Switch>
            <Typography>Mapuzugun</Typography>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default LogkolBar;
