import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import httpClient from '../../HttpClient';
import { Typography, TextField, Stack, Button, Grid, Alert } from '@mui/material';
import Igelal from '../../IngelalPage/Igelal';

const KonalPage = ({ mapuzugun, wigkazugun, mapuzugunPvneal, setZugun, setLoadingZugun }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [credentialsWork, setCredentialsWork] = useState(true);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
      console.log('Componente montado. Verificando datos iniciales...', location.state);
  
      if (isDataLoaded) return;
  
      if (location.state?.redir) {
          console.log('Cargando datos desde location.state...');
          httpClient
              .get(`${process.env.REACT_APP_API_URL}/epuzugun`)
              .then((res) => {
                  setZugun(res.data.mapuzugun, res.data.wigkazugun);
                  setLoading(false);
                  setLoadingZugun(false);
                  setIsDataLoaded(true);
              })
              .catch((error) => {
                  console.error('Error al cargar datos iniciales:', error);
                  setLoading(false);
                  setLoadingZugun(false);
              });
      } else {
          console.log('Cargando datos iniciales...');
          httpClient
              .get(`${process.env.REACT_APP_API_URL}/epuzugun`)
              .then((res) => {
                  setZugun(res.data.mapuzugun, res.data.wigkazugun);
                  setLoading(false);
                  setLoadingZugun(false);
                  setIsDataLoaded(true);
              })
              .catch((error) => {
                  console.error('Error al cargar datos iniciales:', error);
                  setLoading(false);
                  setLoadingZugun(false);
              });
      }
  }, [location.state, setZugun, setLoadingZugun, isDataLoaded]);

    const logInUser = async () => {
        try {
            const resp = await httpClient.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
            console.log('Inicio de sesión exitoso:', resp.status);
            navigate('/', { state: { redir: true } });
        } catch (error) {
            if (error.response.status === 401) {
                setCredentialsWork(false);
            }
        }
    };

    const navigateToPasswordReset = () => {
        navigate('/tukulpatulan_kontrasena', {
            state: { redir: true, mapuzugun, wigkazugun, mapuzugunPvneal },
        });
    };

    return (
        <>
            {loading ? (
                <Igelal />
            ) : (
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Grid item xs={6} md={7}>
                        <img
                            src={`${process.env.PUBLIC_URL}/imgs/back.png`}
                            alt="background"
                            style={{ width: '70%', maxHeight: '70vh', alignSelf: 'center' }}
                        />
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            sx={{
                                marginRight: 15,
                                marginBottom: 15,
                                alignItems: 'center',
                                width: 'auto',
                                minWidth: '280px',
                                maxWidth: '330px',
                            }}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/imgs/logo.svg`}
                                alt="logo"
                                style={{ width: '30%', alignSelf: 'center' }}
                            />
                            <Typography variant="h4">
                                {mapuzugunPvneal ? mapuzugun.konal.titulo : wigkazugun.konal.titulo}
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                required
                                id="password"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button variant="contained" onClick={logInUser} fullWidth>
                                {mapuzugunPvneal ? mapuzugun.konal.konal : wigkazugun.konal.konal}
                            </Button>
                            <Button
                                variant="text"
                                sx={{ marginTop: 2 }}
                                onClick={navigateToPasswordReset}
                            >
                                {mapuzugunPvneal
                                    ? mapuzugun.konal.goyman
                                    : wigkazugun.konal.goyman}
                            </Button>
                            {!credentialsWork && (
                                <Alert severity="warning">
                                    {mapuzugunPvneal
                                        ? mapuzugun.konal.credentialsdoesntwork
                                        : wigkazugun.konal.credentialsdoesntwork}
                                </Alert>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default KonalPage;
