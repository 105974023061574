// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import httpClient from '../HttpClient';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const res = await httpClient.get(process.env.REACT_APP_API_URL + '/@me', {
                    headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
                });
                setUser(res.data);
                setIsAuthenticated(true);
            } catch (error) {
                console.warn('Usuario no autenticado:', error.message);
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        checkAuthentication();
    }, []);

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
